import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import eye from '../../assets/img/svg/illustrations/eye.svg'
import eyeOff from '../../assets/img/svg/illustrations/eye-off.svg'
import './Login.styles.scss'

type Inputs = {
  email: string
  password: string
}
const schema = yup
  .object({
    email: yup
      .string()
      .email()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'E-mail inválido')
      .required('E-mail é obrigatório'),
    password: yup.string().required('Senha é obrigatória'),
  })
  .required()

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const [isVisible, setIsVisible] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const history = useHistory()

  const visibleHandler = () => setIsVisible(!isVisible)

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    setIsLoading(true)
    axios
      .post(`${process.env.REACT_APP_SCRIPT_PORTAL_URL}/auth`, data)
      .then((res: any) => {
        const {
          data: {
            result: { user },
          },
        } = res
        setIsLoading(false)
        if (user?.additional_roles?.includes('live_monitor')) {
          setErrorMessage('')
          localStorage.setItem('liveMonitorUser', JSON.stringify(user))
          history.replace('/monitor')
        } else {
          setErrorMessage('Você não está autenticado!')
        }
      })
      .catch((err: any) => {
        setErrorMessage('Você não está autenticado!')
        setIsLoading(false)
      })
  }

  return (
    <div className="page-content login-wrapper conatiner">
      <h2 className="pt-5 text-center">Login</h2>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <label>E-mail</label>
        <div className="input-wrapper">
          <input {...register('email')} placeholder="Por favor insira o e-mail" className="" />
          {errors.email && <span className="text-danger">{errors?.email?.message}</span>}
        </div>
        <label className="mt-3">Senha</label>
        <div className="input-wrapper ">
          <input {...register('password')} type={isVisible ? 'text' : 'password'} placeholder="Por favor digite a senha" />
          <img src={isVisible ? eyeOff : eye} alt="" className="pwd-img" onClick={visibleHandler} />
          {errors.password && <span className="text-danger">{errors?.password?.message}</span>}
        </div>
        {errorMessage && <div className="mt-4 text-danger text-center">{errorMessage}</div>}
        <div className="btn-wrapper mt-4 mb-5 text-center">
          <button type="submit" className={`btn btn-primary ${isLoading ? 'disabled' : ''}`}>
            Enviar
          </button>
        </div>
      </form>
    </div>
  )
}

export default Login

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { AppLogin } from './AppLogin'
import * as serviceWorker from './serviceWorker'

import '@fortawesome/fontawesome-free/css/all.css'
import './styles/main.scss'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppLogin />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { reverse, sortBy, size, get } from 'lodash'
import { LiveSupport } from '@irontrainers/live-assist/lib/rtdb'
import { useThrottleFn } from '@umijs/hooks'
import moment from 'moment'

import { liveSupport, mainCorporationId } from '../../clients/iron-fit'
import { LiveUserKanbanCardProps } from './components/LiveUserKanbanCard'

export interface LiveSupportKanbanState {
  patientsAwaitingOperator: LiveUserKanbanCardProps[]
  patientsAwaitingOperatorCount: number
  operators: LiveUserKanbanCardProps[]
  patientsAwaitingDoctor: LiveUserKanbanCardProps[]
  patientsAwaitingDoctorCount: number
  doctors: LiveUserKanbanCardProps[]
  patientsAwaitingAssistant: LiveUserKanbanCardProps[]
  patientsAwaitingAssistantCount: number
  assistants: LiveUserKanbanCardProps[]
  activeUsers: number
  psychologists: LiveUserKanbanCardProps[]
  patientsAwaitingPsychologist: LiveUserKanbanCardProps[]
  patientsAwaitingPsychologistCount: number
}

const quitStartTime = moment().subtract(5, 'minutes').valueOf()

export function useLiveSupportKanbanState(status: any, isRefresh: boolean): [LiveSupportKanbanState, boolean] {


  // useEffect(() => {
  const [patientsAwaitingOperator, patientsAwaitingOperatorLoading] = liveSupport.useLivePatientsAwaiting('operator', 'video')
  const [patientsAwaitingDoctor, patientsAwaitingDoctorLoading] = liveSupport.useLivePatientsAwaiting('doctor', 'video')
  const [patientsAwaitingAssistant, patientsAwaitingAssistantLoading] = liveSupport.useLivePatientsAwaiting('assistant', 'video')
  const [patientsAwaitingPsychologist, patientsAwaitingPsychologistLoading] = liveSupport.useLivePatientsAwaiting('psychologist', 'video')
  const [operatorsAwaitingPatient, operatorsAwaitingPatientLoading] = liveSupport.useLiveOperatorsAwaitingPatient('video')
  const [operatorsInSupport, operatorsInSupportLoading] = liveSupport.useLiveOperatorsInSupport(true)
  const [operatorsQuittedRecently, operatorsQuittedRecentlyLoading] = liveSupport.useLiveOperatorsQuittedSince(quitStartTime)
  const [doctorsAwaitingPatient, doctorsAwaitingPatientLoading] = liveSupport.useLiveDoctorsAwaitingPatient('video')
  const [doctorsInSupport, doctorsInSupportLoading] = liveSupport.useLiveDoctorsInSupport(true)
  const [doctorsQuittedRecently, doctorsQuittedRecentlyLoading] = liveSupport.useLiveDoctorsQuittedSince(quitStartTime)
  const [assistantsAwaitingPatient, assistantsAwaitingPatientLoading] = liveSupport.useLiveAssistantsAwaitingPatient('video')
  const [assistantsInSupport, assistantsInSupportLoading] = liveSupport.useLiveAssistantsInSupport(true)
  const [assistantsQuittedRecently, assistantsQuittedRecentlyLoading] = liveSupport.useLiveAssistantsQuittedSince(quitStartTime)
  const [psychologistsAwaitingPatient, psychologistsAwaitingPatientLoading] = liveSupport.useLivePsychologistsAwaitingPatient('video')
  const [psychologistsInSupport, psychologistsInSupportLoading] = liveSupport.useLivePsychologistsInSupport(true)
  const [psychologistsQuittedRecently, psychologistsQuittedRecentlyLoading] = liveSupport.useLivePsychologistsQuittedSince(quitStartTime)

  const [liveSupportsInProgress, liveSupportsInProgressLoading] = liveSupport.useLiveSupports({ status: 'IN_PROGRESS' })
  const [liveSupportsPendingStaffReview, liveSupportsPendingStaffReviewLoading] = liveSupport.useLiveSupports({ pendingStaffReview: true })

  let [kanbanState, setKanbanState] = React.useState<LiveSupportKanbanState>({
    patientsAwaitingOperator: [],
    patientsAwaitingOperatorCount: 0,
    operators: [],
    patientsAwaitingDoctor: [],
    patientsAwaitingDoctorCount: 0,
    doctors: [],
    patientsAwaitingAssistant: [],
    patientsAwaitingAssistantCount: 0,
    assistants: [],
    activeUsers: 0,
    psychologists: [],
    patientsAwaitingPsychologist: [],
    patientsAwaitingPsychologistCount: 0
  })
  function updateKanbanState() {

    // if(isRefresh) {

    const activeUsers: { [userId: string]: boolean } = {}

    const nextPatientsAwaitingOperator: { [userId: string]: LiveUserKanbanCardProps } = {}
    const nextOperators: { [userId: string]: LiveUserKanbanCardProps } = {}
    const nextPatientsAwaitingDoctor: { [userId: string]: LiveUserKanbanCardProps } = {}
    const nextDoctors: { [userId: string]: LiveUserKanbanCardProps } = {}
    const nextPatientsAwaitingAssistant: { [userId: string]: LiveUserKanbanCardProps } = {}
    const nextAssistants: { [userId: string]: LiveUserKanbanCardProps } = {}
    const nextPsychologists: { [userId: string]: LiveUserKanbanCardProps } = {}
    const nextPatientsAwaitingPsychologists: { [userId: string]: LiveUserKanbanCardProps } = {}

    // ======== operators list
    for (const operatorId in operatorsAwaitingPatient) {
      const operator = operatorsAwaitingPatient[operatorId]
      if (mainCorporationId && !get(operator.corporationIds, mainCorporationId, false)) continue
      activeUsers[operatorId] = true
      const operatorCard: LiveUserKanbanCardProps = {
        userId: operatorId,
        userType: 'operator',
        onlineDevices: size(operator.onlineDevices),
        awaitingSince: operator.awaitingPatient ? operator.awaitingPatient.video : undefined,
      }
      nextOperators[operatorId] = operatorCard
    }
    for (const operatorId in operatorsInSupport) {
      const operator = operatorsInSupport[operatorId]
      if (mainCorporationId && !get(operator.corporationIds, mainCorporationId, false)) continue
      activeUsers[operatorId] = true
      let support: Partial<LiveSupport> =
        operator.runningSupportId && liveSupportsInProgress ? liveSupportsInProgress[operator.runningSupportId] || {} : {}
      if (operator.runningSupportId && !support)
        support = liveSupportsPendingStaffReview ? liveSupportsPendingStaffReview[operator.runningSupportId] || {} : {}
      const operatorCard: LiveUserKanbanCardProps = {
        userId: operatorId,
        userType: 'operator',
        onlineDevices: size(operator.onlineDevices),
        queuePriority: support.queuePriority,
        returningSupportId: support.returningSupportId,
        supportId: operator.runningSupportId,
        supportStartTime: support.startTime,
        supportEndTime: support.endTime,
      }
      nextOperators[operatorId] = operatorCard
    }
    for (const operatorId in operatorsQuittedRecently) {
      const operator = operatorsQuittedRecently[operatorId]
      if (mainCorporationId && !get(operator.corporationIds, mainCorporationId, false)) continue
      activeUsers[operatorId] = true
      const operatorCard: LiveUserKanbanCardProps = {
        userId: operatorId,
        userType: 'operator',
        onlineDevices: size(operator.onlineDevices),
        quitSince: operator.quitTime,
      }
      nextOperators[operatorId] = operatorCard
    }

    // ======== doctors list
    for (const doctorId in doctorsAwaitingPatient) {
      const doctor = doctorsAwaitingPatient[doctorId]
      if (mainCorporationId && !get(doctor.corporationIds, mainCorporationId, false)) continue
      activeUsers[doctorId] = true
      const doctorCard: LiveUserKanbanCardProps = {
        userId: doctorId,
        userType: 'doctor',
        onlineDevices: size(doctor.onlineDevices),
        awaitingSince: doctor.awaitingPatient ? doctor.awaitingPatient.video : undefined,
      }
      nextDoctors[doctorId] = doctorCard
    }
    for (const doctorId in doctorsInSupport) {
      const doctor = doctorsInSupport[doctorId]
      if (mainCorporationId && !get(doctor.corporationIds, mainCorporationId, false)) continue
      activeUsers[doctorId] = true
      let support: Partial<LiveSupport> =
        doctor.runningSupportId && liveSupportsInProgress ? liveSupportsInProgress[doctor.runningSupportId] || {} : {}
      if (doctor.runningSupportId && !support)
        support = liveSupportsPendingStaffReview ? liveSupportsPendingStaffReview[doctor.runningSupportId] || {} : {}
      const doctorCard: LiveUserKanbanCardProps = {
        userId: doctorId,
        userType: 'doctor',
        onlineDevices: size(doctor.onlineDevices),
        queuePriority: support.queuePriority,
        returningSupportId: support.returningSupportId,
        supportId: doctor.runningSupportId,
        supportStartTime: support.startTime,
        supportEndTime: support.endTime,
      }
      nextDoctors[doctorId] = doctorCard
    }
    for (const doctorId in doctorsQuittedRecently) {
      const doctor = doctorsQuittedRecently[doctorId]
      if (mainCorporationId && !get(doctor.corporationIds, mainCorporationId, false)) continue
      activeUsers[doctorId] = true
      const doctorCard: LiveUserKanbanCardProps = {
        userId: doctorId,
        userType: 'doctor',
        onlineDevices: size(doctor.onlineDevices),
        quitSince: doctor.quitTime,
      }
      nextDoctors[doctorId] = doctorCard
    }

    // ======== assistants list
    for (const assistantId in assistantsAwaitingPatient) {
      const assistant = assistantsAwaitingPatient[assistantId]
      if (mainCorporationId && !get(assistant.corporationIds, mainCorporationId, false)) continue
      activeUsers[assistantId] = true
      const assistantCard: LiveUserKanbanCardProps = {
        userId: assistantId,
        userType: 'assistant',
        onlineDevices: size(assistant.onlineDevices),
        awaitingSince: assistant.awaitingPatient ? assistant.awaitingPatient.video : undefined,
      }
      nextAssistants[assistantId] = assistantCard
    }
    for (const assistantId in assistantsInSupport) {
      const assistant = assistantsInSupport[assistantId]
      if (mainCorporationId && !get(assistant.corporationIds, mainCorporationId, false)) continue
      activeUsers[assistantId] = true
      let support: Partial<LiveSupport> =
        assistant.runningSupportId && liveSupportsInProgress ? liveSupportsInProgress[assistant.runningSupportId] || {} : {}
      if (assistant.runningSupportId && !support)
        support = liveSupportsPendingStaffReview ? liveSupportsPendingStaffReview[assistant.runningSupportId] || {} : {}
      const assistantCard: LiveUserKanbanCardProps = {
        userId: assistantId,
        userType: 'assistant',
        onlineDevices: size(assistant.onlineDevices),
        queuePriority: support.queuePriority,
        returningSupportId: support.returningSupportId,
        supportId: assistant.runningSupportId,
        supportStartTime: support.startTime,
        supportEndTime: support.endTime,
      }
      nextAssistants[assistantId] = assistantCard
    }
    for (const assistantId in assistantsQuittedRecently) {
      const assistant = assistantsQuittedRecently[assistantId]
      if (mainCorporationId && !get(assistant.corporationIds, mainCorporationId, false)) continue
      activeUsers[assistantId] = true
      const assistantCard: LiveUserKanbanCardProps = {
        userId: assistantId,
        userType: 'assistant',
        onlineDevices: size(assistant.onlineDevices),
        quitSince: assistant.quitTime,
      }
      nextAssistants[assistantId] = assistantCard
    }

    // ======== psychologist list
    for (const psychologistId in psychologistsAwaitingPatient) {
      const psychologist = psychologistsAwaitingPatient[psychologistId]
      if (mainCorporationId && !get(psychologist.corporationIds, mainCorporationId, false)) continue
      activeUsers[psychologistId] = true
      const psychologistCard: LiveUserKanbanCardProps = {
        userId: psychologistId,
        userType: 'psychologist',
        onlineDevices: size(psychologist.onlineDevices),
        awaitingSince: psychologist.awaitingPatient ? psychologist.awaitingPatient.video : undefined,
      }
      nextPsychologists[psychologistId] = psychologistCard
    }
    for (const psychologistId in psychologistsInSupport) {
      const psychologist = psychologistsInSupport[psychologistId]
      if (mainCorporationId && !get(psychologist.corporationIds, mainCorporationId, false)) continue
      activeUsers[psychologistId] = true
      let support: Partial<LiveSupport> =
      psychologist.runningSupportId && liveSupportsInProgress ? liveSupportsInProgress[psychologist.runningSupportId] || {} : {}
      if (psychologist.runningSupportId && !support)
        support = liveSupportsPendingStaffReview ? liveSupportsPendingStaffReview[psychologist.runningSupportId] || {} : {}
      const psychologistCard: LiveUserKanbanCardProps = {
        userId: psychologistId,
        userType: 'psychologist',
        onlineDevices: size(psychologist.onlineDevices),
        queuePriority: support.queuePriority,
        returningSupportId: support.returningSupportId,
        supportId: psychologist.runningSupportId,
        supportStartTime: support.startTime,
        supportEndTime: support.endTime,
      }
      nextPsychologists[psychologistId] = psychologistCard
    }
    for (const psychologistId in psychologistsQuittedRecently) {
      const psychologist = psychologistsQuittedRecently[psychologistId]
      if (mainCorporationId && !get(psychologist.corporationIds, mainCorporationId, false)) continue
      activeUsers[psychologistId] = true
      const psychologistCard: LiveUserKanbanCardProps = {
        userId: psychologistId,
        userType: 'psychologist',
        onlineDevices: size(psychologist.onlineDevices),
        quitSince: psychologist.quitTime,
      }
      nextPsychologists[psychologistId] = psychologistCard
    }

    // ======== patients awaiting operator list

    for (const patientId in patientsAwaitingOperator) {
      const patient = patientsAwaitingOperator[patientId]
      if ((mainCorporationId && patient.corporationId !== mainCorporationId) || (patient && patient.corporationId === 'siriolibanes')) continue

      if (status === "ONLINE") {

        if (size(patient.onlineDevices) > 0) {
          activeUsers[patientId] = true
          const patientCard: LiveUserKanbanCardProps = {
            userId: patientId,
            userType: 'patient',
            onlineDevices: size(patient.onlineDevices),
            awaitingSince: patient.awaitingOperator ? patient.awaitingOperator.video : undefined,
            queuePriority: patient.queuePriority,
            preferredStaffId: patient.preferredStaffId,
            preferredStaffDepartment: patient.preferredStaffDepartment,
            returningSupportId: patient.returningSupportId,
          }
          nextPatientsAwaitingOperator[patientId] = patientCard
        }

      } else if (status === "OFFLINE") {
        if (size(patient.onlineDevices) === 0) {

          activeUsers[patientId] = true
          const patientCard: LiveUserKanbanCardProps = {
            userId: patientId,
            userType: 'patient',
            onlineDevices: size(patient.onlineDevices),
            awaitingSince: patient.awaitingOperator ? patient.awaitingOperator.video : undefined,
            queuePriority: patient.queuePriority,
            preferredStaffId: patient.preferredStaffId,
            preferredStaffDepartment: patient.preferredStaffDepartment,
            returningSupportId: patient.returningSupportId,
          }
          nextPatientsAwaitingOperator[patientId] = patientCard
        }
      } else {
        activeUsers[patientId] = true
        const patientCard: LiveUserKanbanCardProps = {
          userId: patientId,
          userType: 'patient',
          onlineDevices: size(patient.onlineDevices),
          awaitingSince: patient.awaitingOperator ? patient.awaitingOperator.video : undefined,
          queuePriority: patient.queuePriority,
          preferredStaffId: patient.preferredStaffId,
          preferredStaffDepartment: patient.preferredStaffDepartment,
          returningSupportId: patient.returningSupportId,
        }
        nextPatientsAwaitingOperator[patientId] = patientCard

      }





    }

    // ======== patients awaiting doctorlist
    // patientsAwaitingDoctor = sortBy(sortBy(nextPatientsAwaitingDoctor, 'awaitingSince'), 'queuePriority')
    for (const patientId in patientsAwaitingDoctor) {
      const patient = patientsAwaitingDoctor[patientId]
      if ((mainCorporationId && patient.corporationId !== mainCorporationId) || (patient && patient.corporationId === 'siriolibanes')) continue

      if (status === "ONLINE") {
        if (size(patient.onlineDevices) > 0) {
          activeUsers[patientId] = true
          const patientCard: LiveUserKanbanCardProps = {
            userId: patientId,
            userType: 'patient',
            onlineDevices: size(patient.onlineDevices),
            awaitingSince: patient.awaitingDoctor ? patient.awaitingDoctor.video : undefined,
            queuePriority: patient.queuePriority,
            preferredStaffId: patient.preferredStaffId,
            preferredStaffDepartment: patient.preferredStaffDepartment,
            returningSupportId: patient.returningSupportId,
          }
          nextPatientsAwaitingDoctor[patientId] = patientCard

        }

      } else if (status === "OFFLINE") {
        if (size(patient.onlineDevices) === 0) {

          activeUsers[patientId] = true
          const patientCard: LiveUserKanbanCardProps = {
            userId: patientId,
            userType: 'patient',
            onlineDevices: size(patient.onlineDevices),
            awaitingSince: patient.awaitingDoctor ? patient.awaitingDoctor.video : undefined,
            queuePriority: patient.queuePriority,
            preferredStaffId: patient.preferredStaffId,
            preferredStaffDepartment: patient.preferredStaffDepartment,
            returningSupportId: patient.returningSupportId,
          }
          nextPatientsAwaitingDoctor[patientId] = patientCard

        }
      } else {

        activeUsers[patientId] = true
        const patientCard: LiveUserKanbanCardProps = {
          userId: patientId,
          userType: 'patient',
          onlineDevices: size(patient.onlineDevices),
          awaitingSince: patient.awaitingDoctor ? patient.awaitingDoctor.video : undefined,
          queuePriority: patient.queuePriority,
          preferredStaffId: patient.preferredStaffId,
          preferredStaffDepartment: patient.preferredStaffDepartment,
          returningSupportId: patient.returningSupportId,
        }
        nextPatientsAwaitingDoctor[patientId] = patientCard

      }

    }

    // ======== patients awaiting assistant list
    for (const patientId in patientsAwaitingAssistant) {
      const patient = patientsAwaitingAssistant[patientId]
      if (mainCorporationId && patient.corporationId !== mainCorporationId) continue

      if (status === "ONLINE") {
        if (size(patient.onlineDevices) > 0) {

          activeUsers[patientId] = true
          const patientCard: LiveUserKanbanCardProps = {
            userId: patientId,
            userType: 'patient',
            onlineDevices: size(patient.onlineDevices),
            awaitingSince: patient.awaitingAssistant ? patient.awaitingAssistant.video : undefined,
            queuePriority: patient.queuePriority,
            preferredStaffId: patient.preferredStaffId,
            preferredStaffDepartment: patient.preferredStaffDepartment,
            returningSupportId: patient.returningSupportId,
          }
          nextPatientsAwaitingAssistant[patientId] = patientCard

        }
      } else if (status === "OFFLINE") {
        if (size(patient.onlineDevices) === 0) {

          activeUsers[patientId] = true
          const patientCard: LiveUserKanbanCardProps = {
            userId: patientId,
            userType: 'patient',
            onlineDevices: size(patient.onlineDevices),
            awaitingSince: patient.awaitingAssistant ? patient.awaitingAssistant.video : undefined,
            queuePriority: patient.queuePriority,
            preferredStaffId: patient.preferredStaffId,
            preferredStaffDepartment: patient.preferredStaffDepartment,
            returningSupportId: patient.returningSupportId,
          }
          nextPatientsAwaitingAssistant[patientId] = patientCard

        }
      } else {

        activeUsers[patientId] = true
        const patientCard: LiveUserKanbanCardProps = {
          userId: patientId,
          userType: 'patient',
          onlineDevices: size(patient.onlineDevices),
          awaitingSince: patient.awaitingAssistant ? patient.awaitingAssistant.video : undefined,
          queuePriority: patient.queuePriority,
          preferredStaffId: patient.preferredStaffId,
          preferredStaffDepartment: patient.preferredStaffDepartment,
          returningSupportId: patient.returningSupportId,
        }
        nextPatientsAwaitingAssistant[patientId] = patientCard

      }



    }

     // ======== patients awaiting psychologist list
     for (const patientId in patientsAwaitingPsychologist) {
      const patient = patientsAwaitingPsychologist[patientId]
      if (mainCorporationId && patient.corporationId !== mainCorporationId) continue
      if (status === "ONLINE") {
        if (size(patient.onlineDevices) > 0) {
          activeUsers[patientId] = true
          const patientCard: LiveUserKanbanCardProps = {
            userId: patientId,
            userType: 'patient',
            onlineDevices: size(patient.onlineDevices),
            awaitingSince: patient.awaitingPsychologist ? patient.awaitingPsychologist.video : undefined,
            queuePriority: patient.queuePriority,
            preferredStaffId: patient.preferredStaffId,
            preferredStaffDepartment: patient.preferredStaffDepartment,
            returningSupportId: patient.returningSupportId,
          }
          nextPatientsAwaitingPsychologists[patientId] = patientCard
        }
      } else if (status === "OFFLINE") {
        if (size(patient.onlineDevices) === 0) {
          activeUsers[patientId] = true
          const patientCard: LiveUserKanbanCardProps = {
            userId: patientId,
            userType: 'patient',
            onlineDevices: size(patient.onlineDevices),
            awaitingSince: patient.awaitingPsychologist ? patient.awaitingPsychologist.video : undefined,
            queuePriority: patient.queuePriority,
            preferredStaffId: patient.preferredStaffId,
            preferredStaffDepartment: patient.preferredStaffDepartment,
            returningSupportId: patient.returningSupportId,
          }
          nextPatientsAwaitingPsychologists[patientId] = patientCard
        }
      } else {
        activeUsers[patientId] = true
        const patientCard: LiveUserKanbanCardProps = {
          userId: patientId,
          userType: 'patient',
          onlineDevices: size(patient.onlineDevices),
          awaitingSince: patient.awaitingPsychologist ? patient.awaitingPsychologist.video : undefined,
          queuePriority: patient.queuePriority,
          preferredStaffId: patient.preferredStaffId,
          preferredStaffDepartment: patient.preferredStaffDepartment,
          returningSupportId: patient.returningSupportId,
        }
        nextPatientsAwaitingPsychologists[patientId] = patientCard
      }
    }

    // ======== sort lists
    const nextKanbanState: LiveSupportKanbanState = {
      patientsAwaitingOperator: [],
      patientsAwaitingOperatorCount: 0,
      operators: [],
      patientsAwaitingDoctor: [],
      patientsAwaitingDoctorCount: 0,
      doctors: [],
      patientsAwaitingAssistant: [],
      patientsAwaitingAssistantCount: 0,
      assistants: [],
      activeUsers: 0,
      psychologists: [],
      patientsAwaitingPsychologist: [],
      patientsAwaitingPsychologistCount: 0
    }



    nextKanbanState.patientsAwaitingOperator = sortBy(sortBy(nextPatientsAwaitingOperator, 'awaitingSince'), 'queuePriority')

    let prioArray = nextKanbanState.patientsAwaitingOperator.filter(person => { return (person.queuePriority !== undefined) })
    let normalArray = nextKanbanState.patientsAwaitingOperator.filter(person => { return (person.queuePriority === undefined) })


    const masterOpPatient: any = [];

    let maxCount = 0;
    prioArray.forEach(element => {

      if (maxCount < 2) {
        maxCount++;
        masterOpPatient.push(element)
      } else {
        maxCount = 1;
        if (normalArray.length > 0) {
          masterOpPatient.push(normalArray[0])
          normalArray = normalArray.slice(1);
        }
        masterOpPatient.push(element)
      }
    });

    nextKanbanState.patientsAwaitingOperator = masterOpPatient.concat(normalArray)

    nextKanbanState.patientsAwaitingDoctor = sortBy(sortBy(nextPatientsAwaitingDoctor, 'awaitingSince'), 'queuePriority')

    nextKanbanState.patientsAwaitingAssistant = sortBy(sortBy(nextPatientsAwaitingAssistant, 'awaitingSince'), 'queuePriority')
    
    nextKanbanState.patientsAwaitingPsychologist = sortBy(sortBy(nextPatientsAwaitingPsychologists, 'awaitingSince'), 'queuePriority')


    nextKanbanState.patientsAwaitingOperatorCount = nextKanbanState.patientsAwaitingOperator.length
    nextKanbanState.patientsAwaitingDoctorCount = nextKanbanState.patientsAwaitingDoctor.length
    nextKanbanState.patientsAwaitingAssistantCount = nextKanbanState.patientsAwaitingAssistant.length
    nextKanbanState.patientsAwaitingPsychologistCount = nextKanbanState.patientsAwaitingPsychologist.length

    let iCount = 1;
    nextKanbanState.patientsAwaitingOperator.forEach(object => {
      object.indexNum = iCount;
      iCount++
    });

    iCount = 1;
    nextKanbanState.patientsAwaitingDoctor.forEach(object => {
      object.indexNum = iCount;
      iCount++
    });

    iCount = 1;
    nextKanbanState.patientsAwaitingAssistant.forEach(object => {
      object.indexNum = iCount;
      iCount++
    });

    iCount = 1;
    nextKanbanState.patientsAwaitingPsychologist.forEach(object => {
      object.indexNum = iCount;
      iCount++
    });


    let next_op_pts = 0;
    if (localStorage.getItem("next_op_pts") != null) {
      next_op_pts = Number(localStorage.getItem("next_op_pts"));
    }



    let next_doc_pts = 0;
    if (localStorage.getItem("next_doc_pts") != null) {
      next_doc_pts = Number(localStorage.getItem("next_doc_pts"));
    }

    let next_ast_pts = 0;
    if (localStorage.getItem("next_ast_pts") != null) {
      next_ast_pts = Number(localStorage.getItem("next_ast_pts"));
    }

    let next_psy_pts = 0;
    if (localStorage.getItem("next_psy_pts") != null) {
      next_psy_pts = Number(localStorage.getItem("next_psy_pts"));
    }

    let next_op_oprt = 0;
    if (localStorage.getItem("next_op_oprt") != null) {
      next_op_oprt = Number(localStorage.getItem("next_op_oprt"));
    }

    let next_doc = 0;
    if (localStorage.getItem("next_doc") != null) {
      next_doc = Number(localStorage.getItem("next_doc"));
    }

    let next_ast = 0;
    if (localStorage.getItem("next_ast") != null) {
      next_ast = Number(localStorage.getItem("next_ast"));
    }

    let next_psy = 0;
    if (localStorage.getItem("next_psy") != null) {
      next_psy = Number(localStorage.getItem("next_psy"));
    }

    let defaultLoad = 5;
    if (localStorage.getItem("load") != null) {
      defaultLoad = Number(localStorage.getItem("load"));
    }

    if (isRefresh) {

      if (localStorage.getItem("load") != null) {

        const countData = (localStorage.getItem("load") ? localStorage.getItem("load") : 0);
        let myCount = Number(countData)
        // myCount += 5;
        // localStorage.setItem("load", myCount.toString())




        nextKanbanState.patientsAwaitingOperator = nextKanbanState.patientsAwaitingOperator.slice(next_op_pts, myCount + next_op_pts)
        nextKanbanState.patientsAwaitingDoctor = nextKanbanState.patientsAwaitingDoctor.slice(next_doc_pts, myCount + next_doc_pts)
        nextKanbanState.patientsAwaitingAssistant = nextKanbanState.patientsAwaitingAssistant.slice(next_ast_pts, myCount + next_ast_pts)
        nextKanbanState.patientsAwaitingPsychologist = nextKanbanState.patientsAwaitingPsychologist.slice(next_psy_pts, myCount + next_psy_pts)


      } else {

        nextKanbanState.patientsAwaitingOperator = nextKanbanState.patientsAwaitingOperator.slice(next_op_pts, 5)
        nextKanbanState.patientsAwaitingDoctor = nextKanbanState.patientsAwaitingDoctor.slice(next_doc_pts, 5)
        nextKanbanState.patientsAwaitingAssistant = nextKanbanState.patientsAwaitingAssistant.slice(next_ast_pts, 5)
        nextKanbanState.patientsAwaitingPsychologist = nextKanbanState.patientsAwaitingPsychologist.slice(next_psy_pts, 5)

        localStorage.setItem("load", "5")
      }
    } else {
      nextKanbanState.patientsAwaitingOperator = nextKanbanState.patientsAwaitingOperator.slice(0, 5)
      nextKanbanState.patientsAwaitingDoctor = nextKanbanState.patientsAwaitingDoctor.slice(0, 5)
      nextKanbanState.patientsAwaitingAssistant = nextKanbanState.patientsAwaitingAssistant.slice(0, 5)
      nextKanbanState.patientsAwaitingPsychologist = nextKanbanState.patientsAwaitingPsychologist.slice(0, 5)

      localStorage.setItem("load", "5")
      localStorage.removeItem("next_op_pts")
      localStorage.removeItem("next_doc_pts")
      localStorage.removeItem("next_ast_pts")
      localStorage.removeItem("next_psy_pts")


    }

    nextKanbanState.operators = reverse(
      sortBy(nextOperators, (op) => {
        if (op.supportId && op.supportStartTime && !op.supportEndTime) return `${1}_${op.supportStartTime}` // in support
        if (op.supportId && !op.supportStartTime && !op.supportEndTime) return `${3}_${op.supportStartTime}` // reviewing
        if (op.supportId && op.supportStartTime && op.supportEndTime) return `${3}_${op.supportStartTime}` // reviewing
        if (op.quitSince) return `${4}_${op.quitSince}` // video off
        if (op.awaitingSince) return `${2}_${op.awaitingSince}` // waiting
        return '0_'
      })
    )

    nextKanbanState.doctors = reverse(
      sortBy(nextDoctors, (d) => {
        if (d.supportId && d.supportStartTime && !d.supportEndTime) return `${1}_${d.supportStartTime}` // in support
        if (d.supportId && !d.supportStartTime && !d.supportEndTime) return `${3}_${d.supportStartTime}` // reviewing
        if (d.supportId && d.supportStartTime && d.supportEndTime) return `${3}_${d.supportStartTime}` // reviewing
        if (d.quitSince) return `${4}_${d.quitSince}` // video off
        if (d.awaitingSince) return `${2}_${d.awaitingSince}` // waiting
        return '0_'
      })
    )
    nextKanbanState.assistants = reverse(
      sortBy(nextAssistants, (op) => {
        if (op.supportId && op.supportStartTime && !op.supportEndTime) return `${1}_${op.supportStartTime}` // in support
        if (op.supportId && !op.supportStartTime && !op.supportEndTime) return `${3}_${op.supportStartTime}` // reviewing
        if (op.supportId && op.supportStartTime && op.supportEndTime) return `${3}_${op.supportStartTime}` // reviewing
        if (op.quitSince) return `${4}_${op.quitSince}` // video off
        if (op.awaitingSince) return `${2}_${op.awaitingSince}` // waiting
        return '0_'
      })
    )

    nextKanbanState.psychologists = reverse(
      sortBy(nextPsychologists, (op) => {
        if (op.supportId && op.supportStartTime && !op.supportEndTime) return `${1}_${op.supportStartTime}` // in support
        if (op.supportId && !op.supportStartTime && !op.supportEndTime) return `${3}_${op.supportStartTime}` // reviewing
        if (op.supportId && op.supportStartTime && op.supportEndTime) return `${3}_${op.supportStartTime}` // reviewing
        if (op.quitSince) return `${4}_${op.quitSince}` // video off
        if (op.awaitingSince) return `${2}_${op.awaitingSince}` // waiting
        return '0_'
      })
    )



    nextKanbanState.operators = nextKanbanState.operators.slice(next_op_oprt, defaultLoad + next_op_oprt)
    nextKanbanState.doctors = nextKanbanState.doctors.slice(next_doc, defaultLoad + next_doc)
    nextKanbanState.assistants = nextKanbanState.assistants.slice(next_ast, defaultLoad + next_ast)
    nextKanbanState.psychologists = nextKanbanState.psychologists.slice(next_psy, defaultLoad + next_psy)

    nextKanbanState.activeUsers = size(activeUsers)
    setKanbanState(nextKanbanState)

  }

  const { run: throttleUpdateKanbanState } = useThrottleFn(updateKanbanState, 200)

  React.useEffect(throttleUpdateKanbanState, [
    patientsAwaitingOperator,
    patientsAwaitingDoctor,
    patientsAwaitingAssistant,
    patientsAwaitingPsychologist,
    operatorsAwaitingPatient,
    operatorsInSupport,
    operatorsQuittedRecently,
    doctorsAwaitingPatient,
    doctorsInSupport,
    doctorsQuittedRecently,
    assistantsAwaitingPatient,
    assistantsInSupport,
    assistantsQuittedRecently,
    psychologistsAwaitingPatient,
    psychologistsInSupport,
    psychologistsQuittedRecently,
    liveSupportsInProgress,
    liveSupportsPendingStaffReview,
  ]);



  // React.useEffect(throttleUpdateKanbanState, [
  //   patientsAwaitingOperator,
  //   patientsAwaitingDoctor,
  //   patientsAwaitingAssistant,
  //   operatorsAwaitingPatient,
  //   operatorsInSupport,
  //   operatorsQuittedRecently,
  //   doctorsAwaitingPatient,
  //   doctorsInSupport,
  //   doctorsQuittedRecently,
  //   assistantsAwaitingPatient,
  //   assistantsInSupport,
  //   assistantsQuittedRecently,
  //   liveSupportsInProgress,
  //   liveSupportsPendingStaffReview,
  // ])

  const loading =
    patientsAwaitingOperatorLoading ||
    patientsAwaitingDoctorLoading ||
    patientsAwaitingAssistantLoading ||
    patientsAwaitingPsychologistLoading ||
    psychologistsAwaitingPatientLoading ||
    operatorsAwaitingPatientLoading ||
    operatorsInSupportLoading ||
    operatorsQuittedRecentlyLoading ||
    doctorsAwaitingPatientLoading ||
    doctorsInSupportLoading ||
    doctorsQuittedRecentlyLoading ||
    assistantsAwaitingPatientLoading ||
    assistantsInSupportLoading ||
    psychologistsInSupportLoading ||
    assistantsQuittedRecentlyLoading ||
    liveSupportsInProgressLoading ||
    liveSupportsPendingStaffReviewLoading ||
    psychologistsQuittedRecentlyLoading

  return [kanbanState, loading]
  // }, []);
}

import React from 'react'
import { patients } from '../clients/iron-fit'

export const PatientName: React.FC<{ patientId: string }> = ({ patientId }) => {
  const [patient] = patients.usePatient(patientId)
  
  if (!patient) return null
  return <span title={patient?.corporationId}>{patient.name}
  
  {patient.dateOfBirth ? <><br /><br /><div>DoB: {patient.dateOfBirth}</div></> : <></> }
  
  </span>
}

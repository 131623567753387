/* eslint-disable @typescript-eslint/no-unused-vars, jsx-a11y/anchor-is-valid, jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, lazy, useRef, useMemo } from 'react'
import { map, sortBy } from 'lodash'

import { auth, liveSupport, devFlag, patients } from '../../clients/iron-fit'
import './QueueMonitorPage.styles.scss'

import { PageTitle, KanbanWrapper, KanbanCol } from '../../components'

import { LiveUserKanbanCard } from './components/LiveUserKanbanCard'
import { LiveSupportWithSyncPendingItem } from './components/LiveSupportWithSyncPendingItem'
import { useLiveSupportKanbanState } from './live-support-kanban-state.hooks'

// let kanbanState: any, kanbanStateLoading : any
// const KanbanCol = React.lazy(() => import('../../components'));

export const QueueMonitorPage: React.FC = () => {
  const [, userIdLoading] = auth.useAuthUserId()
  const [patientStatus, setPatientStatus] = useState<any>('ONLINE')
  let [isRefresh, setIsRefresh] = useState<any>(false)

  const [kanbanState, kanbanStateLoading] = useLiveSupportKanbanState(patientStatus, isRefresh)

  const [supportsPendingSync, supportsPendingSyncLoading] = liveSupport.useLiveSupportsSync({
    syncPending: devFlag ? true : undefined,
    first: devFlag ? undefined : 1,
  })
  const pendingSync = useMemo(() => {
    const supports = sortBy(
      map(supportsPendingSync, (support, supportId) => ({ supportId, ...support })),
      'updateTime'
    )
    return supports
  }, [supportsPendingSync])

  const loading = userIdLoading || kanbanStateLoading || supportsPendingSyncLoading

  return (
    <div className="page-content queue-monitor-page">
      <PageTitle
        title="Iron Live Support Monitor"
        noteStats={loading ? undefined : kanbanState.activeUsers}
        noteDescription={loading ? undefined : 'Users Active'}
        enableCorpsFilter
        setPatientStatusData={setPatientStatus}
        setIsRefresh={setIsRefresh}
        currentQueue={patientStatus}
        currentStatus={isRefresh}
        isDebug={false}
      />
      <div className="card overflow-hidden">
        <KanbanWrapper>
          <KanbanCol
            title="Triage Patients"
            counter={kanbanState.patientsAwaitingOperator.length}
            total={kanbanState.patientsAwaitingOperatorCount}
          >
            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_op_pts') ? localStorage.getItem('next_op_pts') : 0
                  let nextCount = Number(nextLoad)

                  nextCount -= myCount
                  localStorage.setItem('next_op_pts', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Previous"
                className="ml-1"
              >
                <i className="fas fa-angle-double-left"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }

            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_op_pts') ? localStorage.getItem('next_op_pts') : 0
                  let nextCount = Number(nextLoad)

                  nextCount += myCount
                  localStorage.setItem('next_op_pts', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Next"
                className="ml-1"
              >
                <i className="fas fa-angle-double-right"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }
            {
              <button
                onClick={() => {
                  console.log('REFRSH CLICK')
                  localStorage.removeItem('next_op_pts')
                  setIsRefresh(true)
                }}
                title="Reset"
                className="ml-1"
              >
                Reset
              </button>
            }

            {kanbanState.patientsAwaitingOperator.map((user: any) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>
          <KanbanCol title="Working Operators" counter={kanbanState.operators.length}>
            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_op_oprt') ? localStorage.getItem('next_op_oprt') : 0
                  let nextCount = Number(nextLoad)

                  nextCount -= myCount
                  localStorage.setItem('next_op_oprt', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Previous"
              >
                <i className="fas fa-angle-double-left"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }

            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_op_oprt') ? localStorage.getItem('next_op_oprt') : 0
                  let nextCount = Number(nextLoad)

                  nextCount += myCount
                  localStorage.setItem('next_op_oprt', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Next"
                className="ml-1"
              >
                <i className="fas fa-angle-double-right"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }
            {
              <button
                onClick={() => {
                  localStorage.removeItem('next_op_oprt')
                  setIsRefresh(true)
                }}
                title="Reset"
                className="ml-1"
              >
                Reset
              </button>
            }

            {kanbanState.operators.map((user: any) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>
          <KanbanCol title="Patients" counter={kanbanState.patientsAwaitingDoctor.length} total={kanbanState.patientsAwaitingDoctorCount}>
            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_doc_pts') ? localStorage.getItem('next_doc_pts') : 0
                  let nextCount = Number(nextLoad)

                  nextCount -= myCount
                  localStorage.setItem('next_doc_pts', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Previous"
                className="ml-1"
              >
                <i className="fas fa-angle-double-left"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }

            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_doc_pts') ? localStorage.getItem('next_doc_pts') : 0
                  let nextCount = Number(nextLoad)

                  nextCount += myCount
                  localStorage.setItem('next_doc_pts', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Next"
                className="ml-1"
              >
                <i className="fas fa-angle-double-right"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }
            {
              <button
                onClick={() => {
                  console.log('REFRSH CLICK')
                  localStorage.removeItem('next_doc_pts')
                  setIsRefresh(true)
                }}
                title="Reset"
                className="ml-1"
              >
                Reset
              </button>
            }

            {kanbanState.patientsAwaitingDoctor.map((user: any) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>
          <KanbanCol title="Working Doctors" counter={kanbanState.doctors.length}>
            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_doc') ? localStorage.getItem('next_doc') : 0
                  let nextCount = Number(nextLoad)

                  nextCount -= myCount
                  localStorage.setItem('next_doc', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Previous"
              >
                <i className="fas fa-angle-double-left"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }

            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_doc') ? localStorage.getItem('next_doc') : 0
                  let nextCount = Number(nextLoad)

                  nextCount += myCount
                  localStorage.setItem('next_doc', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Next"
                className="ml-1"
              >
                <i className="fas fa-angle-double-right"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }
            {
              <button
                onClick={() => {
                  localStorage.removeItem('next_doc')
                  setIsRefresh(true)
                }}
                title="Reset"
                className="ml-1"
              >
                Reset
              </button>
            }

            {kanbanState.doctors.map((user: any) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>
          <KanbanCol title="Patients" counter={kanbanState.patientsAwaitingAssistant.length} total={kanbanState.patientsAwaitingAssistantCount}>
            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_ast_pts') ? localStorage.getItem('next_ast_pts') : 0
                  let nextCount = Number(nextLoad)

                  nextCount -= myCount
                  localStorage.setItem('next_ast_pts', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Previous"
                className="ml-1"
              >
                <i className="fas fa-angle-double-left"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }

            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_ast_pts') ? localStorage.getItem('next_ast_pts') : 0
                  let nextCount = Number(nextLoad)

                  nextCount += myCount
                  localStorage.setItem('next_ast_pts', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Next"
                className="ml-1"
              >
                <i className="fas fa-angle-double-right"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }
            {
              <button
                onClick={() => {
                  localStorage.removeItem('next_ast_pts')
                  setIsRefresh(true)
                }}
                title="Reset"
                className="ml-1"
              >
                Reset
              </button>
            }

            {kanbanState.patientsAwaitingAssistant.map((user: any) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>
          <KanbanCol title="Working Assistants" counter={kanbanState.assistants.length}>
            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_ast') ? localStorage.getItem('next_ast') : 0
                  let nextCount = Number(nextLoad)

                  nextCount -= myCount
                  localStorage.setItem('next_ast', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Previous"
              >
                <i className="fas fa-angle-double-left"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }

            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_ast') ? localStorage.getItem('next_ast') : 0
                  let nextCount = Number(nextLoad)

                  nextCount += myCount
                  localStorage.setItem('next_ast', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Next"
                className="ml-1"
              >
                <i className="fas fa-angle-double-right"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }
            {
              <button
                onClick={() => {
                  localStorage.removeItem('next_ast')
                  setIsRefresh(true)
                }}
                title="Reset"
                className="ml-1"
              >
                Reset
              </button>
            }

            {kanbanState.assistants.map((user: any) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>
          <KanbanCol title="Patients" counter={kanbanState.patientsAwaitingPsychologist.length} total={kanbanState.patientsAwaitingPsychologistCount}>
            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_psy_pts') ? localStorage.getItem('next_psy_pts') : 0
                  let nextCount = Number(nextLoad)

                  nextCount -= myCount
                  localStorage.setItem('next_psy_pts', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Previous"
                className="ml-1"
              >
                <i className="fas fa-angle-double-left"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }

            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_psy_pts') ? localStorage.getItem('next_psy_pts') : 0
                  let nextCount = Number(nextLoad)

                  nextCount += myCount
                  localStorage.setItem('next_psy_pts', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Next"
                className="ml-1"
              >
                <i className="fas fa-angle-double-right"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }
            {
              <button
                onClick={() => {
                  localStorage.removeItem('next_psy_pts')
                  setIsRefresh(true)
                }}
                title="Reset"
                className="ml-1"
              >
                Reset
              </button>
            }

            {kanbanState.patientsAwaitingPsychologist.map((user: any) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>

          <KanbanCol title="Working Psychologists" counter={kanbanState.psychologists.length}>
            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_psy') ? localStorage.getItem('next_psy') : 0
                  let nextCount = Number(nextLoad)

                  nextCount -= myCount
                  localStorage.setItem('next_psy', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Previous"
              >
                <i className="fas fa-angle-double-left"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }

            {
              <button
                onClick={() => {
                  const countData = localStorage.getItem('load') ? localStorage.getItem('load') : 0
                  let myCount = Number(countData)

                  const nextLoad = localStorage.getItem('next_psy') ? localStorage.getItem('next_psy') : 0
                  let nextCount = Number(nextLoad)

                  nextCount += myCount
                  localStorage.setItem('next_psy', nextCount.toString())
                  setIsRefresh(true)
                }}
                title="Next"
                className="ml-1"
              >
                <i className="fas fa-angle-double-right"></i> ({(localStorage.getItem('load') ? localStorage.getItem('load') : 5)?.toString()})
              </button>
            }
            {
              <button
                onClick={() => {
                  localStorage.removeItem('next_psy')
                  setIsRefresh(true)
                }}
                title="Reset"
                className="ml-1"
              >
                Reset
              </button>
            }

            {kanbanState.psychologists.map((user: any) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>
          {devFlag && (
            <KanbanCol title="Synchronizing" counter={pendingSync.length}>
              {pendingSync.map((support) => (
                <LiveSupportWithSyncPendingItem key={support.supportId} supportId={support.supportId} support={support} />
              ))}
            </KanbanCol>
          )}
        </KanbanWrapper>
      </div>
      {/* {isFetching && (
        <div className="d-flex m-auto spinner-border text-primary mr-2" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )} */}
    </div>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/img-redundant-alt */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { isAuthenticatedViaLogin } from './clients/iron-fit'
import { LiveDebugPage, QueueMonitorPage } from './pages'
import Login from './pages/Login'
import { ManageStaffQueue } from './pages/ManageStaffQueue'

const AuthRoute: React.FC<any> = ({ Component }) => {
  return (
    <div className={`container-fluid container-application ${window.location.pathname === '/login' ? 'login' : ''}`}>
      <div className="main-content position-relative">
        <Component />
      </div>
    </div>
  )
}

export const AppLogin: React.FC = () => {
  return (
    <Switch>
      <Route path="/login">
        <div className={`container-fluid container-application login`}>
          <Login />
        </div>
      </Route>
      <Route path="/live-debug">
        <AuthRoute Component={LiveDebugPage} />
      </Route>
      <Route path="/staff-load">
        <AuthRoute Component={ManageStaffQueue} />
      </Route>
      <Route path="/monitor">
        <AuthRoute Component={QueueMonitorPage} />
      </Route>
      <Route path="/" exact>
        {!isAuthenticatedViaLogin ? (
          <div className={`container-fluid container-application login`}>
            <Login />
          </div>
        ) : (
          <AuthRoute Component={QueueMonitorPage} />
        )}
      </Route>
    </Switch>
  )
}

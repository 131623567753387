/* eslint-disable @typescript-eslint/no-unused-vars, jsx-a11y/anchor-is-valid, jsx-a11y/img-redundant-alt */
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import ironFit from '../../clients/iron-fit'
import './ManageStaffQueue.styles.scss'
import axios from 'axios'
import Swal from 'sweetalert2'

export const ManageStaffQueue: React.FC = () => {
  const [corporationId, setCorporationId] = useState<string>('')
  const [corporations, setCorporations] = useState<any>()
  const [isUpdateStaffLoad, setIsUpdateStaffLoad] = useState<boolean>(false)
  const [updateStaffLoad, setUpdateStaffLoad] = useState<{ operator: number; doctor: number }>({ operator: 0, doctor: 0 })
  const [currentStaffLoad, setCurrentStaffLoad] = useState<{ operator: number; doctor: number }>({ operator: 0, doctor: 0 })
  const api = ironFit._clients().api

  React.useEffect(() => {
    getCorporationList()
  }, [])

  const getCorporationList = async () => {
    axios
      .get(`${process.env.REACT_APP_IRON_FIT_CORPORATION}doctor/getAllCorporation`)
      .then((res: any) => {
        const {
          data: { data },
        } = res
        setCorporations(data)
      })
      .catch((err: any) => console.log('err', err))
  }

  const getStaffLoad = async () => {
    try {
      setIsUpdateStaffLoad(false)
      const res = await api.get(`/live-support/corporation/${corporationId}`)
      const {
        data: { data: staffLoad },
      } = res
      setCurrentStaffLoad({ ...staffLoad })
      
      if (staffLoad) setUpdateStaffLoad({ ...staffLoad })
      setIsUpdateStaffLoad(true)
    } catch (error: any) {
      setIsUpdateStaffLoad(false)
      warningMessage(error?.response?.message || error?.response?.data || 'Something went wrong')
    }
  }

  const handleUpdateStaffLoad = async () => {
    try {
      const res = await api.put(`/live-support/corporation/${corporationId}`, { ...updateStaffLoad })
      const {
        data: { message },
      } = res
      warningMessage(message)
    } catch (error: any) {
      warningMessage(error?.response?.message || error?.response?.data || 'Something went wrong')
    }
  }

  const handleUpdateStaffLoadState = (e: { target: { value: string; name: string } }) => {
    setUpdateStaffLoad({ ...updateStaffLoad, [e.target.name]: Number(e.target.value) })
  }

  const warningMessage = React.useCallback(async (message: string) => {
    Swal.fire({
      title: message,
      confirmButtonText: 'Ok',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsUpdateStaffLoad(false)
        setCorporationId('')
      }
    })
  }, [])

  return (
    <div className="page-content manage-staff-page conatiner">
      <h2 className="pt-3">Atualize a Gestão de Fila</h2>
      <div className="mt-4">
        <span>Cliente: </span>
        <select value={corporationId} onChange={(e) => setCorporationId(e.target.value)}>
          <option disabled selected value={''}>
            Selecione...
          </option>
          {corporations?.map((c: { slug: string; name: string }) => (
            <option key={c.slug} value={c.slug}>
              {c.slug}
            </option>
          ))}
        </select>
        <br />
        <button type="button" className="btn btn-primary mt-2" onClick={getStaffLoad} disabled={isEmpty(corporationId)}>
          Carregar valores atuais
        </button>
      </div>

      {isUpdateStaffLoad && (
        <>
          <div className="row mt-5">
            <div className="col-md-6">
              <h5>By-pass atual</h5>
              <p>
                Enfermeiro: <span>{currentStaffLoad?.operator}</span>
              </p>
              <p>
                Médico: <span>{currentStaffLoad?.doctor}</span>{' '}
              </p>
            </div>
            <div className="col-md-6">
              <h5>Insira o novo valor de By-pass</h5>
              <p>
                Enfermeiro: <input type="text" name="operator" value={updateStaffLoad?.operator} onChange={handleUpdateStaffLoadState} />{' '}
              </p>
              <p>
                Médico: <input type="text" name="doctor" value={updateStaffLoad?.doctor} onChange={handleUpdateStaffLoadState} />{' '}
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 text-center">
              <button type="button" className="btn btn-primary" onClick={handleUpdateStaffLoad} disabled={isEmpty(corporationId)}>
                Atualizar
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
